<template>
  <div v-loading="loading">
    <el-table
      ref="multipleTable"
      row-key="id"
      border
      :data="tableData"
      :row-style="rowStyle"
      :row-class-name="tableRowClassName"
      highlight-current-row
      :default-expand-all="true"
      tooltip-effect="dark"
      height="string"
      v-loading="loading"
    >
      <el-table-column align="center" type="index" label="序号"></el-table-column>
      <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
      <el-table-column align="center" prop="taskDate" label="时间">
        <template slot-scope="scope">
          {{ scope.row.taskDate | dateFormat }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="content" label="内容"></el-table-column>
      <el-table-column align="center" prop="clientName" label="客户"></el-table-column>
    </el-table>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {},
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      condition: {
        title: '',
      },
      form: {},
      edit_form: {},
      tableData: [],
      // forList:[],
      loading: false,
      type: '',
      fileCode: 'SWZJ',
      add_form: {},
      file_Data: {
        loading: false,
      },
      // activeData: {},
      index: 0,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  inject: ['re'],
  props: {
    BusinessId: {},
  },
  watch: {
    BusinessId: {
      immediate: true,
      handler: function (a) {
        if (a) {
          this.getData(a)
        }
      },
    },
  },
  created() {},
  methods: {
    getData(id) {
      this.loading = true
      this.$api.businessManage
        .getDisposeInfo(id)
        .then(res => {
          this.loading = false
          this.tableData = res.data
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    upload(r) {
      r.some(res => {
        this.file_Data = res.combination({
          fileCode: this.fileCode,
        })
      })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    rowStyle(res) {
      if (res.rowIndex == this.index) {
        return {
          background: '#f5f7fa',
        }
      } else {
        return {}
      }
    },
    // rowClick(row) {
    //     this.activeData = Object.assign({},row.deepClone());
    //     this.index = row.index;
    // },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

// .annex {
//     display:flex;
//     justify-content: space-between;
//     /deep/.el-card {
//         width:50%;
//         margin:0 10px;
//         .el-card__header {
//             padding:10px 15px;
//             font-size:16px;
//             font-weight:bold;
//         }
//         .el-table {
//             height:calc(100vh - 347px);
//         }
//     }

// }
.el-table {
  height: calc(100vh - 347px);
}
</style>
